import { Pipe, PipeTransform } from '@angular/core';
import {Pant} from './pant';

@Pipe({
  name: 'pantIdFilter'
})
export class PantIdFilterPipe implements PipeTransform {

  transform(value: Pant[], args: any): Pant[] {
    let excludedId = args;
    let pants: Pant[] = [];
    for (let pant of value) {
      if (pant.id !== excludedId) {
        pants.push(pant);
      }
    }
    return pants;
  }

}
