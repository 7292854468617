export class Pant {
    id: bigint;
    name: string;
    brand: string;
    rise: number;
    thigh: number;
    knee: number;
    ankle: number;
    image: string;
}
