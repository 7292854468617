import { Component, OnInit } from '@angular/core';

import { PantsService } from '../pants.service';
import { Pant } from '../pant';

import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-pants-list',
  templateUrl: './pants-list.component.html',
  styleUrls: ['./pants-list.component.scss']
})
export class PantsListComponent implements OnInit {
    pants: Pant[];
    pants$: Observable<Pant[]>;
    private searchTerms = new Subject<string>();

    constructor(private pantsService: PantsService) {}

    search(term: string): void {
        this.searchTerms.next(term);
    }

    getPants() {
        this.pantsService.getAll().subscribe(pants => this.pants = pants);
    }

    ngOnInit(): void {
        this.getPants();
        this.pants$ = this.searchTerms.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) => this.pantsService.searchName(term)),
        );
    }
}
