import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { PantsListComponent } from './pants-list/pants-list.component';
import { CompareComponent } from './compare/compare.component';

const routes: Routes = [
    { path: '', redirectTo: '/list', pathMatch: 'full' },
    { path: 'list', component: PantsListComponent },
    { path: 'compare/:id', component: CompareComponent }
];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled'
};

@NgModule({
    imports: [ RouterModule.forRoot(routes, routerOptions) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
