export class PantConstants {
    riseMin: number = 9;
    riseMax: number = 12;
    thighMin: number = 10;
    thighMax: number = 13;
    kneeMin: number = 7;
    kneeMax: number = 11;
    ankleMin: number = 6;
    ankleMax: number = 10.5;
}
