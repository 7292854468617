import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { PantsListComponent } from './pants-list/pants-list.component';
import { AppRoutingModule } from './app-routing.module';
import { CompareComponent } from './compare/compare.component';
import { SliderComponent } from './slider/slider.component';
import { SuggestionComponent } from './suggestion/suggestion.component';
import { PantIdFilterPipe } from './pant-id-filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    PantsListComponent,
    CompareComponent,
    SliderComponent,
    SuggestionComponent,
    PantIdFilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
