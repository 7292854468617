import { Pant } from './pant';

export class SuggestionParameters {
    rise: number;
    thigh: number;
    knee: number;
    ankle: number;

    constructor(base: Pant) {
        this.rise = base.rise;
        this.thigh = base.thigh;
        this.knee = base.knee;
        this.ankle = base.ankle;
    }
}
