import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Pant } from './pant';
import { SuggestionParameters } from './suggestion-parameters';

@Injectable({
    providedIn: 'root'
})
export class PantsService {

    private url = 'http://pantsdiff-api.firstmatedavy.com/pants';

    constructor(private http: HttpClient) {}

    getAll(): Observable<Pant[]> {
        return this.http.get<Pant[]>(`${this.url}/getAll`);
    }

    get(id: number): Observable<Pant> {
        const url = `${this.url}/get/${id}`;
        return this.http.get<Pant>(url);
    }

    searchName(term: string): Observable<Pant[]> {
        if (!term.trim()) {//todo: I'm not sure this actually does anything
            return this.getAll();
        }
        return this.http.get<Pant[]>(`${this.url}/search/${term}`);
    }

    suggestPants(parameters: SuggestionParameters): Observable<Pant[]> {
        const jsonParameters = JSON.stringify(parameters);
        return this.http.get<Pant[]>(`${this.url}/suggest/${jsonParameters}`);
    }
}
