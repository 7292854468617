import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PantsService } from '../pants.service';
import { PantConstants } from '../pant-constants';
import { Pant } from '../pant';
import {SuggestionParameters} from '../suggestion-parameters';

@Component({
    selector: 'app-compare',
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit {
    constants: PantConstants = new PantConstants();
    pant: Pant = new Pant();
    suggestionParameters: SuggestionParameters;
    suggestedPants: Pant[] = [];

    constructor(
        private route: ActivatedRoute,
        private pantsService: PantsService
    ) {}

    ngOnInit() {
        this.loadPant();
    }

    loadPant(): void {
        const id = +this.route.snapshot.paramMap.get('id');
        this.pantsService.get(id).subscribe(pant => this.pant = pant);
    }

    updateSuggestions(property: string, value: number): void {
        if (this.suggestionParameters === undefined) {
            this.suggestionParameters = new SuggestionParameters(this.pant);
        }
        this.suggestionParameters[property] = value;
        this.pantsService.suggestPants(this.suggestionParameters).subscribe(
            pants => this.suggestedPants = pants
        );
    }

    scrollToSuggestions(): void {
        document.getElementById('suggestedPants').scrollIntoView();
    }
}
