import {Component, Input} from '@angular/core';

import { PantConstants } from '../pant-constants';
import { Pant } from '../pant';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss']
})
export class SuggestionComponent {
  constants: PantConstants = new PantConstants();
  @Input() pant: Pant;

  constructor() { }

  purchase() {
    // todo: get affiliate link from the database and open in new tab
    alert(
        'Purchase links haven\'t been implemented yet. ' +
        'If you want to buy these pants, ' +
        'try searching the name and brand in the search engine of your choice.'
    );
  }
}
