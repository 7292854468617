import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnChanges {
    @Input() id: string;
    @Input() header: string;
    @Input() minLabel: string;
    @Input() maxLabel: string;
    @Input() min: number;
    @Input() max: number;
    @Input() currentValue: number;
    @Input() disabled: boolean = false;
    @Output() valueEmitter = new EventEmitter();
    sliderPosition: number = 0;
    isSet: boolean = false;

    constructor() {}

    emit(value: number) {
        this.valueEmitter.emit(value);
    }

    ngOnChanges() {
        var sliderPercent: number;
        var sliderElement = document.getElementById('compareSliderThigh-new');
        var ghostThumbWidth: number = parseFloat(getComputedStyle(document.documentElement).fontSize);
        if (sliderElement != null && this.max > 0) {
            this.isSet = true;
            sliderPercent = (this.currentValue - this.min) / (this.max - this.min);
            this.sliderPosition = (sliderElement.clientWidth - ghostThumbWidth) * sliderPercent;
        }
    }
}
